import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";

import map_marker_image from "../../images/common/cupid_main.png";
import mark_time_image from "../../images/common/mark_time.png";
import mark_rest_image from "../../images/common/mark_rest.png";
import mark_ad_image from "../../images/common/mark_ad.png";
import mark_tel_image from "../../images/common/mark_tel.png";
import open_link_image from "../../images/common/open_link.svg";
import { postData } from "../../lib/apiHelper";
import NotFound from "./NotFound";
import GoogleMapReact from 'google-map-react';
import FacebookWidget from "../Widgets/FacebookWidget";
import TwitterWidget from "../Widgets/TwitterWidget";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faLine } from '@fortawesome/free-brands-svg-icons'
import { TwitterShareButton, FacebookShareButton, LineShareButton } from "react-share";
import logo_instagram from "../../images/common/logo_instagram.png";

const google_map_key = process.env.REACT_APP_GOOGLE_MAP_KEY;
const facebook_app_id = process.env.REACT_APP_FACEBOOK_APP_ID;

function ShopDetail() {
  const { shop_code } = useParams();
  const [shop, setShop] = useState(null);
  const [isValidMap, setIsValidMap] = useState(true);
  const history = useHistory();

  useEffect(() => {
    postData('/shops/detail', { shop_code }).then(data => {
      // Check invalid mapdata
      let [lat, lng] = data.shop_info.mapdata.split(', ').map(x => parseFloat(x));
      if (!lat || !lng) setIsValidMap(false);

      setShop(data.shop_info || false);
    }).catch(e => {
      setShop(false);
      console.error(e);
    })
  }, [shop_code]);

  const shopContent = () => {
    if (shop === null) return ' ';
    if (shop === false) return <NotFound />;

    let [lat, lng] = shop.mapdata.split(', ').map(x => parseFloat(x));

    const defaultProps = {
      center: { lat, lng },
      zoom: 17
    };

    const Marker = () => {
      return <div className="google-pin">
        <div className="shop-address">{shop.name}</div>
        <img src={map_marker_image} alt="marker" />
      </div>
    };

    const currentUrl = window.location.href;

    return (
      <section id="main" className="shop-info">
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12">
            <div className="text-shop-name">{shop.name}</div>

            <p className="mb-30 py-4">
              {shop.description}
            </p>

            <div className="row">
              <div className="col-12 mb-5">
                <div className="sns-icon-container">
                  <FacebookShareButton url={currentUrl} className="fl-share-button fl-facebook-share-button">
                    <FontAwesomeIcon icon={faFacebookF} className='mr-1' /> シェア
                  </FacebookShareButton>
                </div>

                <div className="sns-icon-container">
                  <TwitterShareButton url={currentUrl} className="fl-share-button fl-twitter-share-button">
                    <FontAwesomeIcon icon={faTwitter} className='mr-1' /> ツイート
                  </TwitterShareButton>
                </div>

                <div className="sns-icon-container">
                  <LineShareButton url={currentUrl} className="fl-share-button fl-line-share-button">
                    <FontAwesomeIcon icon={faLine} className='mr-1' /> LINEで送る
                  </LineShareButton>
                </div>
              </div>
            </div>

            <div className='my-3'>
              <div className='row'>
                <div className="col-sm-6">
                  <div className="d-flex align-items-start shop-info-line">
                    <img className="contact-image" src={mark_tel_image} alt="" />
                    <div>{shop.tel}</div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex align-items-start shop-info-line">
                    <img className="contact-image" src={mark_ad_image} alt="" />
                    <div>{shop.address}</div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-sm-6">
                  <div className="d-flex align-items-start shop-info-line">
                    <img className="contact-image" src={mark_time_image} alt="" />
                    <div>{shop.eigyo}</div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex align-items-start shop-info-line">
                    <img className="contact-image" src={mark_rest_image} alt="" />
                    <div>{shop.teikyu}</div>
                  </div>
                </div>
              </div>
            </div>

            {
              shop.web && shop.web.match(/^http/) ? (
                <div className="text-center mb-30 mt-5">
                  <a className="btn-go-shop" href={shop.web} target="_blank" rel="noopener noreferrer">
                    ウェブサイト
                    <img src={open_link_image} alt="" />
                  </a>
                </div>
              ) : null
            }
          </div>

          <div className="col-12 col-md-6 col-sm-12">
            <img src={"http://www.kanagawa879.com/uploads/blogs/" + shop.blog_id + "/topimage.jpg?" + (new Date()).getTime()} alt="" style={{ width: '100%', objectFit: 'contain', maxHeight: '460px' }} />
          </div>
        </div>

        <div className={'row mt-1'
          + (shop.facebook_embed_url || (shop.facebook_embed_url && shop.twitter_username && shop.instagram_access_token) ? ' flex-row-reverse justify-content-start ' : '')
          + (!shop.facebook_embed_url && shop.twitter_username && shop.instagram_access_token ? ' justify-content-end ' : '')
        }>
          {
            !!shop.facebook_embed_url && <FacebookWidget
              href={shop.facebook_embed_url}
              appId={facebook_app_id}
              containerClasses="col-12 col-md-6 col-sm-12 mt-4 fb-iframe"
            />
          }
          {isValidMap && <div className="col-12 col-md-6 col-sm-12 mt-4" style={{ height: '500px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: google_map_key }}
              defaultCenter={{ lat: defaultProps.center.lat, lng: defaultProps.center.lng }}
              defaultZoom={defaultProps.zoom}>
              <Marker lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
            </GoogleMapReact>
          </div>}
          {
            !!shop.twitter_username && <TwitterWidget
              username={shop.twitter_username}
              containerClasses="col-12 col-md-6 col-sm-12 mt-4 twitter-iframe"
            />
          }
          {/* { 
            !!shop.instagram_access_token && <InstagramWidget
              token={ shop.instagram_access_token }
              containerClasses="col-12 col-md-6 col-sm-12 mt-4 instagram-iframe"
            /> 
          } */}

          {/* Instagram */}
          {!!shop.instagram_embed_url && <div className='col-12 col-md-6 col-sm-12 mt-4 instagram-iframe'>
            <p className="w-100 d-flex align-items-center mb-2">
              <img src={logo_instagram} alt="" style={{ height: '45px', paddingRight: '10px' }} />
            </p>
            <script src="https://snapwidget.com/js/snapwidget.js"></script>
            <div dangerouslySetInnerHTML={{ __html: shop.instagram_embed_url }} />
          </div>}
        </div>
      </section>
    )
  };

  return (
    <div id="shop_detail" className="container mb-5">

      <span className='text-primary btn-back font-weight-bold' onClick={() => history.goBack()}>
        <i className="fa-solid fa-chevron-left"></i> <span>店舗一覧</span>
      </span>
      {shopContent()}
    </div>
  );
}

export default ShopDetail;
