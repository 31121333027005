import React from 'react';

import not_found_image from "../../images/common/not_found.png";

function NotFound() {
    return (
      <div className="not-found-container">
          <img src={not_found_image} alt="not found" />
      </div>
    )
}

export default NotFound;
