import React, { memo, useEffect, useState } from 'react';
import SHOP_WALL from "../../images/shop/shopwall.jpg"
import { useHistory, useLocation } from 'react-router-dom';
import ShopItem from 'components/ShopList/ShopItem';
import { getData } from 'lib/apiHelper';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ShopList = memo(() => {
    const searchParams = new URLSearchParams(useLocation().search);
    const mode = searchParams.get('mode');
    const search = searchParams.get('search');
    const name = searchParams.get('name');
    const history = useHistory();

    const [shops, setShops] = useState([]);

    useEffect(() => {
        getData(`/shops/${mode}/${search}`).then(result => {
            const { data } = result;
            setShops(data);
        }).catch(e => {
            setShops([]);
            console.error(e);
        })
    }, [search, mode])

    return (
        <div className='container-fluid p-0'>
            <div className='information-banner'>
                <img src={SHOP_WALL} alt='Shop.png' />
                <h1 className='information-header'>SEARCH</h1>
            </div>

            <div className='shoplist-container'>
                <span className='text-primary btn-back font-weight-bold' onClick={() => history.goBack()}>
                    <i className="fa-solid fa-chevron-left"></i> <span>検索ページに戻る</span>
                </span>
                <h2 className='information-title mt-0'>「横浜市鶴見区」 {mode === 'places' ? name : search}</h2>
                {shops.map(shop => <ShopItem key={shop.code} shop={shop} />)}
            </div>

        </div>
    )
})

export default ShopList