import React, { memo } from 'react';
import ABOUT_WALL from "../../images/about/aboutwall.jpg"
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

const data = [
    {
        code: "34-118",
        name: "海戸園",
        vote: 105,
        img: "325.b011bab56c7946ff3adf2736d654de73.1.jpg"
    },
    {
        code: "34-173",
        name: "アプレミディ",
        vote: 68,
        img: "529.eb69bd2a51ca5e109a0018d8d430eb27.1.jpeg"
    }
]

const Ranking = memo(() => {
    return (
        <div className='container-fluid p-0'>
            <div className='information-banner'>
                <img src={ABOUT_WALL} alt='flowers.png' />
                <h1 className='information-header'>RANKING</h1>
            </div>
            <div className='ranking-container' style={{ maxWidth: 1200 }}>
                <h2 className='information-title'>人気投票の結果</h2>
                <Table id="ranking-table">
                    <Thead>
                        <Tr>
                            <Th>順位</Th>
                            <Th>コード</Th>
                            <Th>店名</Th>
                            <Th>得票数数</Th>
                            <Th>Model</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((shop, index) => <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{shop.code}</Td>
                            <Td>{shop.name}</Td>
                            <Td>{shop.vote}</Td>
                            <Td><img src={`https://kanagawa879.com/uploads/submit/${shop.img}`} alt='ranking.png'/></Td>
                        </Tr>)}
                    </Tbody>
                </Table>
            </div>
        </div>
    )
})

export default Ranking