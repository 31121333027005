import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import SHOP_WALL from "../../images/shop/shopwall.jpg"
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { getData } from 'lib/apiHelper';
import routes from 'lib/routes';
import { Link, useHistory, useLocation } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Marker from 'components/Shops/Marker';
import { useRecoilState } from 'recoil';
import { mapShopsRecoil } from 'recoil/shop';
import scrollToId from 'components/Shops/scrollToId';
import MapSearchByAddress from 'components/Shops/MapSearchByAddress';
import { centerMapShopsRecoil, searchMarkerRecoil } from 'recoil/map';

const cities = {
  1: '横浜市',
  2: '川崎市',
  0: '',
}

const defaultProps = {
  center: {
    lat: 35.46738105960409,
    lng: 139.43023681640625
  },
  zoom: 9.9
}

const Shops = memo(() => {
  const [shops, setShops] = useRecoilState(mapShopsRecoil);
  const [center, setCenter] = useRecoilState(centerMapShopsRecoil);
  const [searchMarker] = useRecoilState(searchMarkerRecoil);

  const [places, setPlaces] = useState([]);
  const [search, setSearch] = useState('');

  const history = useHistory();

  const location = useLocation();
  const scrolledId = location.state?.scrolledId;

  useEffect(() => {
    getData('/shops/places').then(result => {
      const { data } = result;
      const formatPlaces = {};
      for (const place of data) {
        formatPlaces[place.parentPlaceId] = formatPlaces[place.parentPlaceId] ? [...formatPlaces[place.parentPlaceId], place] : [place]
      }
      setPlaces(formatPlaces)
    }).catch(e => {
      setPlaces([]);
      console.error(e)
    });

    getData('/shops').then(result => {
      const { data } = result;
      const newData = [...data].map(shop => {
        shop.showInfo = false;
        return shop
      });
      setShops(newData);
    }).catch(e => {
      console.error(e)
    });
  }, [setShops]);

  useEffect(() => {
    if (places.length !== 0) scrollToId(scrolledId, -55);
  }, [scrolledId, places.length])

  const closePopUps = useCallback(() => {
    const newShops = [...shops].map(shop => {
      const newShop = { ...shop, showInfo: false }
      return newShop;
    });
    setShops(newShops);
  }, [shops, setShops]);

  const openPopup = useCallback((code) => {
    const shopData = shops.find(shop => shop.code === code);
    if (shopData && !shopData.showInfo) {
      const newShops = [...shops].map(shop => {
        if (shop.code === code) return { ...shop, showInfo: true }
        else return { ...shop, showInfo: false }
      });
      setShops(newShops);

      const lat = Number(shopData.mapdata.split(',')[0]);
      const lng = Number(shopData.mapdata.split(',')[1]);
      setCenter({ lat: lat + 0.15, lng });
    }
  }, [shops, setShops, setCenter])

  const togglePopUp = useCallback((code) => {
    const shop = shops.find(shop => shop.code === code);
    if (shop.showInfo) closePopUps();
    else openPopup(code);
  }, [shops, closePopUps, openPopup])

  const markers = useMemo(() => {
    const tmpMarks = [];
    for (const shop of shops) {
      if (shop.mapdata) {
        const lat = Number(shop.mapdata.split(',')[0]);
        const lng = Number(shop.mapdata.split(',')[1]);
        if (Boolean(lat) && Boolean(lng)) tmpMarks.push(
          <Marker shop={shop} key={shop.code} lat={lat} lng={lng} togglePopUp={togglePopUp} />
        )
      }
    }
    return tmpMarks;
  }, [shops, togglePopUp]);

  return (
    <div className='container-fluid p-0'>
      <div className='information-banner'>
        <img src={SHOP_WALL} alt='Shop.png' />
        <h1 className='information-header'>SEARCH</h1>
      </div>
      <div className='search-container'>
        <div className='search-group'>
          <h2 className='information-title m-0 mb-4'>店舗名で探す</h2>
          <InputGroup size='lg' >
            <FormControl
              placeholder="店舗名・住所など"
              onChange={(e) => {
                setSearch(e.currentTarget.value)
              }}
            />
            <InputGroup.Append>
              <Button onClick={e => {
                const params = new URLSearchParams({
                  mode: "name",
                  search: search.trim(),
                }).toString();
                history.push(`${routes.SHOPLIST}?${params}`)
              }}>検索</Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
        <h2 className='information-title' id='title-search-cities'>市区町名で探す</h2>
        <hr className='search-line' />
        <div>
          {[1, 2, 0].map((parentPlaceId) => <div key={parentPlaceId}>
            <div className='row mx-0'>
              <div className={`${cities[parentPlaceId] ? 'search-city' : ''} col-lg-3`}>
                {cities[parentPlaceId]}
              </div>
              <div className='col-lg-9 row p-4'>
                {places[parentPlaceId] && places[parentPlaceId].map(place => <div className='col-6 col-sm-4 col-md-3 col-lg-20-percent my-2' key={place.placeId}>
                  <Link to={{
                    pathname: `${routes.SHOPLIST}`,
                    search: `?${new URLSearchParams({
                      mode: "places",
                      search: place.placeId,
                      name: place.name
                    }).toString()}`
                  }}><i className="fa-solid fa-chevron-right search-list-arrow"></i> <span className='search-list-place'>{place.name}</span></Link>
                </div>)}
              </div>
            </div>
            <hr className='search-line' /></div>)}
        </div>
        <h2 className='information-title' id='title-search-map'>マップから探す</h2>
        <div className='gg-map-group'>
          <MapSearchByAddress />
          <div className="gg-map-container">
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
              center={center}
              defaultZoom={defaultProps.zoom}
            >
              {markers}
              {searchMarker ? <i lat={searchMarker.lat} lng={searchMarker.lng} className="fa-solid fa-location-dot" style={{
                color: "red",
                fontSize: 24
              }}></i> : <></>}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Shops