import React, { memo, useCallback, useState } from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { RequestType, geocode, setDefaults } from 'react-geocode';
import { useRecoilState } from 'recoil';
import { centerMapShopsRecoil, searchMarkerRecoil } from 'recoil/map';
import { popUpError } from 'lib/popupHelper';

setDefaults({
    key: process.env.REACT_APP_GOOGLE_MAP_KEY,
    language: process.env.NODE_ENV === 'production' ? "ja" : "vi",
    region: process.env.NODE_ENV === 'production' ? "jp" : "vn"
});

const MapSearchByAddress = memo(() => {
    const [searchMarker, setSearchMarker] = useRecoilState(searchMarkerRecoil);
    const [center, setCenter] = useRecoilState(centerMapShopsRecoil);

    const [value, setValue] = useState('');

    const handleSearchByAddress = useCallback(async (address) => {
        if (address.length) {
            try {
                const data = await geocode(RequestType.ADDRESS, address);
                // const data = {
                //     "results": [
                //         {
                //             "address_components": [
                //                 {
                //                     "long_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "short_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "types": [
                //                         "airport",
                //                         "establishment",
                //                         "point_of_interest"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Trường Sơn",
                //                     "short_name": "Trường Sơn",
                //                     "types": [
                //                         "route"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Tân Bình",
                //                     "short_name": "Tân Bình",
                //                     "types": [
                //                         "administrative_area_level_2",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Thành phố Hồ Chí Minh",
                //                     "short_name": "Thành phố Hồ Chí Minh",
                //                     "types": [
                //                         "administrative_area_level_1",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Việt Nam",
                //                     "short_name": "VN",
                //                     "types": [
                //                         "country",
                //                         "political"
                //                     ]
                //                 }
                //             ],
                //             "formatted_address": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA), Trường Sơn, Phường 2, Tân Bình, Thành phố Hồ Chí Minh, Việt Nam",
                //             "geometry": {
                //                 "location": {
                //                     "lat": 10.816143,
                //                     "lng": 106.6640352
                //                 },
                //                 "location_type": "GEOMETRIC_CENTER",
                //                 "viewport": {
                //                     "northeast": {
                //                         "lat": 10.8172448802915,
                //                         "lng": 106.6653572802915
                //                     },
                //                     "southwest": {
                //                         "lat": 10.8145469197085,
                //                         "lng": 106.6626593197085
                //                     }
                //                 }
                //             },
                //             "partial_match": true,
                //             "place_id": "ChIJnZ-oGhEpdTER8ycbqsCc8Ng",
                //             "plus_code": {
                //                 "compound_code": "RM87+FJ Tân Bình, Thành phố Hồ Chí Minh",
                //                 "global_code": "7P28RM87+FJ"
                //             },
                //             "types": [
                //                 "airport",
                //                 "establishment",
                //                 "point_of_interest"
                //             ]
                //         },
                //         {
                //             "address_components": [
                //                 {
                //                     "long_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "short_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "types": [
                //                         "airport",
                //                         "establishment",
                //                         "point_of_interest"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Trường Sơn",
                //                     "short_name": "Trường Sơn",
                //                     "types": [
                //                         "route"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Tân Bình",
                //                     "short_name": "Tân Bình",
                //                     "types": [
                //                         "administrative_area_level_2",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Thành phố Hồ Chí Minh",
                //                     "short_name": "Thành phố Hồ Chí Minh",
                //                     "types": [
                //                         "administrative_area_level_1",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Việt Nam",
                //                     "short_name": "VN",
                //                     "types": [
                //                         "country",
                //                         "political"
                //                     ]
                //                 }
                //             ],
                //             "formatted_address": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA), Trường Sơn, Phường 2, Tân Bình, Thành phố Hồ Chí Minh, Việt Nam",
                //             "geometry": {
                //                 "location": {
                //                     "lat": 10.816143,
                //                     "lng": 106.6640352
                //                 },
                //                 "location_type": "GEOMETRIC_CENTER",
                //                 "viewport": {
                //                     "northeast": {
                //                         "lat": 10.8172448802915,
                //                         "lng": 106.6653572802915
                //                     },
                //                     "southwest": {
                //                         "lat": 10.8145469197085,
                //                         "lng": 106.6626593197085
                //                     }
                //                 }
                //             },
                //             "partial_match": true,
                //             "place_id": "ChIJnZ-oGhEpdTER8ycbqsCc8Nf",
                //             "plus_code": {
                //                 "compound_code": "RM87+FJ Tân Bình, Thành phố Hồ Chí Minh",
                //                 "global_code": "7P28RM87+FJ"
                //             },
                //             "types": [
                //                 "airport",
                //                 "establishment",
                //                 "point_of_interest"
                //             ]
                //         },
                //         {
                //             "address_components": [
                //                 {
                //                     "long_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "short_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "types": [
                //                         "airport",
                //                         "establishment",
                //                         "point_of_interest"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Trường Sơn",
                //                     "short_name": "Trường Sơn",
                //                     "types": [
                //                         "route"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Tân Bình",
                //                     "short_name": "Tân Bình",
                //                     "types": [
                //                         "administrative_area_level_2",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Thành phố Hồ Chí Minh",
                //                     "short_name": "Thành phố Hồ Chí Minh",
                //                     "types": [
                //                         "administrative_area_level_1",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Việt Nam",
                //                     "short_name": "VN",
                //                     "types": [
                //                         "country",
                //                         "political"
                //                     ]
                //                 }
                //             ],
                //             "formatted_address": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA), Trường Sơn, Phường 2, Tân Bình, Thành phố Hồ Chí Minh, Việt Nam",
                //             "geometry": {
                //                 "location": {
                //                     "lat": 10.816143,
                //                     "lng": 106.6640352
                //                 },
                //                 "location_type": "GEOMETRIC_CENTER",
                //                 "viewport": {
                //                     "northeast": {
                //                         "lat": 10.8172448802915,
                //                         "lng": 106.6653572802915
                //                     },
                //                     "southwest": {
                //                         "lat": 10.8145469197085,
                //                         "lng": 106.6626593197085
                //                     }
                //                 }
                //             },
                //             "partial_match": true,
                //             "place_id": "ChIJnZ-oGhEpdTER8ycbqsCc8N2",
                //             "plus_code": {
                //                 "compound_code": "RM87+FJ Tân Bình, Thành phố Hồ Chí Minh",
                //                 "global_code": "7P28RM87+FJ"
                //             },
                //             "types": [
                //                 "airport",
                //                 "establishment",
                //                 "point_of_interest"
                //             ]
                //         },
                //         {
                //             "address_components": [
                //                 {
                //                     "long_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "short_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "types": [
                //                         "airport",
                //                         "establishment",
                //                         "point_of_interest"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Trường Sơn",
                //                     "short_name": "Trường Sơn",
                //                     "types": [
                //                         "route"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Tân Bình",
                //                     "short_name": "Tân Bình",
                //                     "types": [
                //                         "administrative_area_level_2",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Thành phố Hồ Chí Minh",
                //                     "short_name": "Thành phố Hồ Chí Minh",
                //                     "types": [
                //                         "administrative_area_level_1",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Việt Nam",
                //                     "short_name": "VN",
                //                     "types": [
                //                         "country",
                //                         "political"
                //                     ]
                //                 }
                //             ],
                //             "formatted_address": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA), Trường Sơn, Phường 2, Tân Bình, Thành phố Hồ Chí Minh, Việt Nam",
                //             "geometry": {
                //                 "location": {
                //                     "lat": 10.816143,
                //                     "lng": 106.6640352
                //                 },
                //                 "location_type": "GEOMETRIC_CENTER",
                //                 "viewport": {
                //                     "northeast": {
                //                         "lat": 10.8172448802915,
                //                         "lng": 106.6653572802915
                //                     },
                //                     "southwest": {
                //                         "lat": 10.8145469197085,
                //                         "lng": 106.6626593197085
                //                     }
                //                 }
                //             },
                //             "partial_match": true,
                //             "place_id": "ChIJnZ-oGhEpdTER8ycbqsCc8N3",
                //             "plus_code": {
                //                 "compound_code": "RM87+FJ Tân Bình, Thành phố Hồ Chí Minh",
                //                 "global_code": "7P28RM87+FJ"
                //             },
                //             "types": [
                //                 "airport",
                //                 "establishment",
                //                 "point_of_interest"
                //             ]
                //         },
                //         {
                //             "address_components": [
                //                 {
                //                     "long_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "short_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "types": [
                //                         "airport",
                //                         "establishment",
                //                         "point_of_interest"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Trường Sơn",
                //                     "short_name": "Trường Sơn",
                //                     "types": [
                //                         "route"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Tân Bình",
                //                     "short_name": "Tân Bình",
                //                     "types": [
                //                         "administrative_area_level_2",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Thành phố Hồ Chí Minh",
                //                     "short_name": "Thành phố Hồ Chí Minh",
                //                     "types": [
                //                         "administrative_area_level_1",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Việt Nam",
                //                     "short_name": "VN",
                //                     "types": [
                //                         "country",
                //                         "political"
                //                     ]
                //                 }
                //             ],
                //             "formatted_address": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA), Trường Sơn, Phường 2, Tân Bình, Thành phố Hồ Chí Minh, Việt Nam",
                //             "geometry": {
                //                 "location": {
                //                     "lat": 10.816143,
                //                     "lng": 106.6640352
                //                 },
                //                 "location_type": "GEOMETRIC_CENTER",
                //                 "viewport": {
                //                     "northeast": {
                //                         "lat": 10.8172448802915,
                //                         "lng": 106.6653572802915
                //                     },
                //                     "southwest": {
                //                         "lat": 10.8145469197085,
                //                         "lng": 106.6626593197085
                //                     }
                //                 }
                //             },
                //             "partial_match": true,
                //             "place_id": "ChIJnZ-oGhEpdTER8ycbqsCc8N4",
                //             "plus_code": {
                //                 "compound_code": "RM87+FJ Tân Bình, Thành phố Hồ Chí Minh",
                //                 "global_code": "7P28RM87+FJ"
                //             },
                //             "types": [
                //                 "airport",
                //                 "establishment",
                //                 "point_of_interest"
                //             ]
                //         },
                //         {
                //             "address_components": [
                //                 {
                //                     "long_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "short_name": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA)",
                //                     "types": [
                //                         "airport",
                //                         "establishment",
                //                         "point_of_interest"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Trường Sơn",
                //                     "short_name": "Trường Sơn",
                //                     "types": [
                //                         "route"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Tân Bình",
                //                     "short_name": "Tân Bình",
                //                     "types": [
                //                         "administrative_area_level_2",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Thành phố Hồ Chí Minh",
                //                     "short_name": "Thành phố Hồ Chí Minh",
                //                     "types": [
                //                         "administrative_area_level_1",
                //                         "political"
                //                     ]
                //                 },
                //                 {
                //                     "long_name": "Việt Nam",
                //                     "short_name": "VN",
                //                     "types": [
                //                         "country",
                //                         "political"
                //                     ]
                //                 }
                //             ],
                //             "formatted_address": "Cảng hàng không Quốc tế Tân Sơn Nhất (TIA), Trường Sơn, Phường 2, Tân Bình, Thành phố Hồ Chí Minh, Việt Nam",
                //             "geometry": {
                //                 "location": {
                //                     "lat": 10.816143,
                //                     "lng": 106.6640352
                //                 },
                //                 "location_type": "GEOMETRIC_CENTER",
                //                 "viewport": {
                //                     "northeast": {
                //                         "lat": 10.8172448802915,
                //                         "lng": 106.6653572802915
                //                     },
                //                     "southwest": {
                //                         "lat": 10.8145469197085,
                //                         "lng": 106.6626593197085
                //                     }
                //                 }
                //             },
                //             "partial_match": true,
                //             "place_id": "ChIJnZ-oGhEpdTER8ycbqsCc8N5",
                //             "plus_code": {
                //                 "compound_code": "RM87+FJ Tân Bình, Thành phố Hồ Chí Minh",
                //                 "global_code": "7P28RM87+FJ"
                //             },
                //             "types": [
                //                 "airport",
                //                 "establishment",
                //                 "point_of_interest"
                //             ]
                //         }
                //     ],
                //     "status": "OK"
                // }
                // const data = null;
                if (data?.results) {
                    setCenter(data.results[0].geometry.location);
                    setSearchMarker(data.results[0].geometry.location);
                } else {
                    popUpError({ title: "住所が見つかりません!" });
                }
            } catch (error) {
                console.log(error)
                popUpError({ title: "住所が見つかりません!" });
            }
        } else {
            setSearchMarker(null);
            setCenter({
                lat: 35.46738105960409,
                lng: 139.43023681640625
            })
        }
    }, [setCenter, setSearchMarker]);

    return (
        <div className='gg-map-input-group'>
            <InputGroup size={'lg'} >
                <FormControl
                    className='map-input-search'
                    placeholder="マップから探す"
                    value={value}
                    onChange={(e) => {
                        setValue(e.currentTarget.value)
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') handleSearchByAddress(value)
                    }}
                />
                <InputGroup.Append>
                    <Button onClick={e => {
                        handleSearchByAddress(value);
                    }}>検索</Button>
                </InputGroup.Append>
            </InputGroup>
        </div>
    )
})

export default MapSearchByAddress