import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Button = styled.button`
    color: #ffffff;
    background-color: #8ec456;
    border-color: #8ec456;
    width: 160px;
    height: 50px;
    margin: 10px;
`;

const FlowerImage = styled.img`
    width: 260px;
    height: 170px;
    background-color: gray;
    object-fit: cover;
`;

const Send = ({
  show = false,
  onHide,
  onSend,
  image_base_url,
  selected
}) => {

  const [values, setValues] = useState({
    email: '',
    name: ''
  })
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setValues({
      ...values, ...{
        [name]: value
      }
    });
  }

  if (show === false) return null;
  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-90w"
        aria-labelledby="send-custom-modal-styling-title"
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id="send-custom-modal-styling-title">
            <h2 className="shop-title">人気投票</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} md={{ span: 6, offset: 3 }}>
                <div id="main">
                  <div className="detail">
                    <div className="inner clearfix">
                      <div>
                        <h4>選択した画像</h4>
                        <div className="d-flex justify-content-between mb-3">
                          {selected[0] && selected[0].information.information.images.image[0] !== null &&
                            <div className="shop-flower-image d-flex justify-content-center">
                              <FlowerImage
                                src={`${image_base_url}submit/${selected[0].information.information.images.image[0]}`}
                              />
                            </div>
                          }
                          {selected[1] && selected[1].information.information.images.image[0] !== null &&
                            <div className="shop-flower-image d-flex justify-content-center">
                              <FlowerImage
                                src={`${image_base_url}submit/${selected[1].information.information.images.image[0]}`}
                              />
                            </div>
                          }
                          {selected[2] && selected[2].information.information.images.image[0] !== null &&
                            <div className="shop-flower-image d-flex justify-content-center">
                              <FlowerImage
                                src={`${image_base_url}submit/${selected[2].information.information.images.image[0]}`}
                              />
                            </div>
                          }
                        </div>
                        <div className="">
                          <h4>メールアドレスとお名前をご入力下さい。</h4>
                          <Form>
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>メールアドレス</Form.Label>
                              <Form.Control type="email" name="email" value={values.email} onChange={handleInputChange} placeholder="メールアドレス" required />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>お名前</Form.Label>
                              <Form.Control type="input" name="name" value={values.name} onChange={handleInputChange} placeholder="お名前" required />
                            </Form.Group>
                            <Button className="btn" onClick={onHide}>キャンセル</Button>
                            <Button className="btn" onClick={(e) => { e.preventDefault(); onSend(values) }}>送信する</Button>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Send;