import React, { memo } from 'react';
import map_marker_image from "../../images/common/cupid_main.png";

const Marker = memo(({shop, togglePopUp}) => {
  return <div onClick={e => e.preventDefault()}>
    <div style={{
      display: shop.showInfo ? 'block' : 'none',
    }} className='shop-marker-popup'>
      <h4 className='text-center shop-marker-title'>{shop.name}</h4>
      <div className='shop-marker-image' style={{
        background: `url(https://www.kanagawa879.com/uploads/blogs/${shop.blog_id}/topimage.jpg)`,
      }}>
      </div>
      {shop.description && <hr />}
      <p className='shop-marker-content'>{shop.description.length > 48 ? shop.description.substring(0, 45) + '...' : shop.description}</p>
      <i className="fa-solid fa-xmark shop-marker-close" onClick={() => togglePopUp(shop.code)}></i>
      <div className='shop-marker-arrow-down'></div>
    </div>
    <img src={map_marker_image} alt="marker" onClick={(e) => {
      togglePopUp(shop.code)
    }} className='shop-marker-location'/>
  </div>
});

  export default Marker;