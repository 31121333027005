import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal'

const ShopImage = styled.img`
    width: 410px;
`;

const Button = styled.button`
    color: #ffffff;
    background-color: #8ec456;
    border-color: #8ec456;
    width: 160px;
    height: 50px;
    margin: 10px;
`;

const Comment = styled.div`
    font-size: 16px;
    padding: 10px;
    border: 1px dotted;
    margin-bottom: 10px;
`;

const ShopImages = ({
    shop,
    show = false,
    onHide,
    onSelect = false,
    image_base_url
}) => {
    if (show === false) return null;
    return (
        <>
            <Modal
                show={show}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                onHide={onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h2 className="shop-title">{shop.name}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="main">
                        <div class="detail">
                            <div class="inner clearfix">
                                <div class="lBox">
                                    {shop.information.information.comment !== '' &&
                                        <Comment>{shop.information.information.comment}</Comment>
                                    }
                                    <div className="d-flex justify-content-between mb-3">
                                        <div className="shop-flower-image">
                                            {shop.information.information.images.image[0] !== null &&
                                                <img
                                                    src={`${image_base_url}submit/${shop.information.information.images.image[0]}`}
                                                    alt=""
                                                />
                                            }
                                        </div>
                                        <div className="shop-flower-image">
                                            {shop.information.information.images.image[1] !== null &&
                                                <img
                                                    src={`${image_base_url}submit/${shop.information.information.images.image[1]}`}
                                                    alt=""
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div className="shop-flower-image">
                                            {shop.information.information.images.image[2] !== null &&
                                                <img
                                                    src={`${image_base_url}submit/${shop.information.information.images.image[2]}`}
                                                    alt=""
                                                />
                                            }
                                        </div>
                                        <div className="shop-flower-image">
                                            {shop.information.information.images.image[3] !== null &&
                                                <img
                                                    src={`${image_base_url}submit/${shop.information.information.images.image[3]}`}
                                                    alt=""
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div className="shop-flower-image">
                                            {shop.information.information.images.image[4] !== null &&
                                                <img
                                                    src={`${image_base_url}submit/${shop.information.information.images.image[4]}`}
                                                    alt=""
                                                />
                                            }
                                        </div>
                                        <div className="shop-flower-image d-flex align-items-center justify-content-center">
                                            <div className="d-flex flex-column">
                                                {onSelect !== false &&
                                                    <div>
                                                        <Button className="btn" onClick={() => { onSelect(shop) }}>選択する</Button>
                                                    </div>
                                                }
                                                <div>
                                                    <Button className="btn" onClick={onHide}>閉じる</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="rBox">
                                    <div class="photo">
                                        <ShopImage src={`${image_base_url}blogs/${shop.blog_id}/topimage.jpg`} alt={shop.name} />
                                    </div>
                                    <dl class="clearfix">
                                        {shop.address !== '' &&
                                            <>
                                                <dt>住所</dt>
                                                <dd>{shop.address}</dd>
                                            </>
                                        }
                                        {shop.tel !== '' &&
                                            <>
                                                <dt>Tel</dt>
                                                <dd><a href={`tel:${shop.tel}`}>{shop.tel}</a></dd>
                                            </>
                                        }
                                        {shop.eigyo !== '' &&
                                            <>
                                                <dt>営業時間</dt>
                                                <dd>{shop.eigyo}</dd>
                                            </>
                                        }
                                        {shop.teikyu !== '' &&
                                            <>
                                                <dt>定休日</dt>
                                                <dd>{shop.teikyu}</dd>
                                            </>
                                        }
                                        {shop.access !== '' &&
                                            <>
                                                <dt>アクセス</dt>
                                                <dd>{shop.access}</dd>
                                            </>
                                        }
                                    </dl>
                                    <p>{shop.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ShopImages;
