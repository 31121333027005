export default function scrollToId(idElement, offset) {
    let scrollTo = 0;
    if (idElement) {
        const element = document.getElementById(idElement);
        if (element) {
            const rect = element.getBoundingClientRect();
            scrollTo = rect.top + window.scrollY + offset;
        }
    }
    window.scrollTo({
        top: scrollTo,
        left: 0,
        behavior: "smooth"
    })
}