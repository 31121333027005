import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from 'react-router-dom';
import './index.css';
import './App.css';
import Vote from './components/pages/vote';
import Top from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import Recommend from './components/pages/recommend';
import * as serviceWorker from './serviceWorker';
import Home from "./components/pages/Home";
import ShopInfo from "./components/pages/ShopDetail";
import Contact from 'components/pages/Contact';
import About from 'components/pages/About';
import routes from 'lib/routes';
import Shops from 'components/pages/Shops';
import ShopList from 'components/pages/ShopList';
import Ranking from 'components/pages/Ranking';
import { RecoilRoot } from 'recoil';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <Switch>
          <Route path={routes.VOTE}>
            <Vote />
          </Route>
          <Route path={routes.RECOMMEND}>
            <Recommend />
          </Route>
          <Route path={routes.ABOUT}>
            <div id="home" className='container'>
              <Top />
              <About />
              <Footer />
            </div>
          </Route>
          <Route path={routes.RANKING}>
            <div id="home" className='container'>
              <Top />
              <Ranking />
              <Footer />
            </div>
          </Route>
          <Route path={routes.CONTACT}>
            <div id="home" className='container'>
              <Top />
              <Contact />
              <Footer />
            </div>
          </Route>
          <Route path={routes.SHOPS}>
            <div id="home" className='container'>
              <Top />
              <Shops />
              <Footer />
            </div>
          </Route>
          <Route path={routes.SHOPLIST}>
            <div id="home" className='container'>
              <Top />
              <ShopList />
              <Footer />
            </div>
          </Route>
          <Route path={routes.SHOPINFO} match>
            <div id="home" className='container'>
              <Top />
              <ShopInfo />
              <Footer />
            </div>
          </Route>
          <Route path={routes.HOME} match>
            <div id="home" className='container'>
              <Top />
              <Home />
              <Footer />
            </div>
          </Route>
          <Route path="/" match>
            <div id="home" className='container'>
              <Top />
              <Home />
              <Footer />
            </div>
          </Route>
        </Switch>
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
