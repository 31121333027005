import React, { useState, useEffect } from 'react';
import Flower from '../Flower';
import styled from 'styled-components';
import ShopImages from '../ShopImages';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
// import Send from '../Send';

const API_HOST = process.env.REACT_APP_API_HOST;
const seminar_id = process.env.REACT_APP_RECOMEND_ID;

const image_base_url = "https://kanagawa879.com/uploads/";

const Frame = styled.div`
  display: flex;
  justify-content: space-between;
`;

// const Footbar = styled.div`
//   background-color: #8ec456;
//   height: 60px;
//   width: 100%;
//   position: fixed;
//   bottom: 0;
//   margin-left: -65px;
//   color: white;
//   font-size: 1.5em;
//   font-weight: bold;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const SendBtn = styled.button`
//     margin-left: 30px;
//     background: white;
//     border: 2px solid #5b7d37;
//     font-size: 1em;
//     border-radius: 7px;
//     padding: 5px 20px;
//     cursor: pointer;
// `;
const Description = styled.div`
    text-align: left;
    margin-bottom: 20px;
    margin-top: -20px;
`;
// const Notice = styled.p`
//   font-size: 1.2em !important;
//   margin-bottom: 4px !important;
// `;
const Theme = styled.div`
  font-size: 1.6em !important;
  margin-bottom: 4px !important;
  text-align: center;
  color: #8ec456;
  text-shadow: 2px 2px 4px #8ec456;
  font-weight: bold;
`;
const Layout = styled.div`
  font-family: 'Noto Sans JP', sans-serif;
`;

function Vote() {

  const selectFlower = (flower) => {
    let newSelected;
    if (selected.some(f => f.id === flower.id)) {
      newSelected = selected.filter((f) => f.id !== flower.id)
    } else {
      if (selected.length > 2) {
        alert('3件まで選択できます。')
        return false;
      }
      newSelected = [...selected, flower]
    }
    setSeleced(newSelected);
  }

  const selectShop = (id, user_id, seminar_id) => {
    axios
      .get(`${API_HOST}/api/v1/flowers/get/${seminar_id}/${user_id}/${id}`)
      .then(response => {
        setShop(response.data.shop[0]);
        setShow(true);
      })
      .catch(() => {
        console.log('通信に失敗しました');
      });
  }

  // const onSendClick = () => {
  //   setShowSend(true);
  // }

  // const sendData = (values) => {
  //   axios
  //     .post(`${API_HOST}/api/v1/flowers/send`, {
  //       name: values.name,
  //       email: values.email,
  //       selected: selected
  //     })
  //     .then(response => {
  //       console.log('送信しました');
  //       setShowSend(false);
  //       setSeleced([]);
  //       alert('投票を送信しました。\n御協力ありがとうございました。');
  //     })
  //     .catch(() => {
  //       console.log('通信に失敗しました');
  //     });
  // }

  const [selected, setSeleced] = useState([]);
  const [show, setShow] = useState(false);
  // const [showSend, setShowSend] = useState(false);
  const [flowers, setFlowers] = useState([]);
  const [shop, setShop] = useState({});

  useEffect(() => {
    axios
      .get(`${API_HOST}/api/v1/flowers/all/${seminar_id}`)
      .then(response => {
        setFlowers(response.data.flowers);
      })
      .catch(() => {
        console.log('通信に失敗しました');
      });
  }, []);


  const sliceByNumber = (array, number) => {
    const length = Math.ceil(array.length / number)
    return new Array(length).fill().map((_, i) =>
      array.slice(i * number, (i + 1) * number)
    )
  }

  const slicedFlowers = sliceByNumber(flowers, 4);

  const frames = slicedFlowers.map((flowers, j) => {
    const flowerSelects = flowers.map((flower, i) => {
      return (
        <Flower
          entryno={(j * 4) + i + 1}
          name={flower.name}
          src={`${image_base_url}submit/${flower.information.information.images.image[0]}`}
          selected={selected.some(f => f.id === flower.id)}
          selectFlower={() => selectFlower(flower)}
          selectShop={() => selectShop(flower.id, flower.user_id, seminar_id)}
          key={`flower-select-${i}`}
        />
      )
    })
    return (
      <Frame key={`frame-${j}`}>
        {flowerSelects}
      </Frame>
    )
  })

  return (
    <Layout>
      fasdfasd
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap" rel="stylesheet" />
			<div className="content">
				<h2 className="headLine01">各店のおすすめ<span>Recomend</span></h2>
			</div>
      <Description>
        <Theme>
          テーマ「お歳暮に最適!! 当店おすすめのウインターフラワーギフト」
        </Theme>
      </Description>
      {frames}
      <ShopImages
        show={show}
        shop={shop}
        image_base_url={image_base_url}
        onHide={() => setShow(false)}
        onSelect={false}
      />
    </Layout>
  );
}

export default Vote;
