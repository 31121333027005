import { Skeleton } from 'antd';
import React, { useState } from 'react';
import {Timeline} from "react-twitter-widgets";

function TwitterWidget(props) {

  const [isLoaded, setIsLoaded] = useState(false);

  if (!props.username) return null;

  return (
    <div className={props.containerClasses || ''} id='twitter-widget'>
      <Timeline
        dataSource={{
          sourceType: 'profile',
          screenName: props.username
        }}
        options={{
          tweetLimit: 1,
          height: '500',
          chrome: 'noborders',
          lang: 'ja'
        }}
        onLoad={() => {
          setIsLoaded(true);
        }}
      />
      {!isLoaded && <>
        {Array(15).fill(0).map((item, index) => <Skeleton.Input key={index} block={true} active={true} style={{marginBottom: 5}}/>)}
      </>}
    </div>
  );
}

export default TwitterWidget;
