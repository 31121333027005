import React, { useState } from 'react';

import top_image_1 from '../../images/top/topimage01withtext.png';
import f_banner01_icon from '../../images/common/f_banner01.jpg';
import f_banner02_icon from '../../images/common/f_banner02.jpg';
import icon_search from '../../images/top/icon_search.svg';
import icon_search_city from '../../images/top/icon_search_city.png';
import icon_search_map from '../../images/top/icon_search_map.png';
import ranking01_icon from '../../images/top/ranking01.png';
// import ranking02_icon from '../../images/top/ranking02.png';
import image02withSquare from '../../images/top/image02withsquare.png';
import image03withSquare from '../../images/top/image03withsquare.png';
import image04 from '../../images/top/image04.jpg';
// import image04witText from '../../images/top/image04wittext.png';
// import FacebookWidget from "../Widgets/FacebookWidget";
// import InstagramWidget from "../Widgets/InstagramWidget";
import TwitterWidget from "../Widgets/TwitterWidget";
import FacebookWidget from 'components/Widgets/FacebookWidget';
import logo_instagram from "../../images/common/logo_instagram.png";
import { Link, useHistory } from 'react-router-dom';
import routes from 'lib/routes';

const twitter_username = process.env.REACT_APP_TWITTER_USERNAME;
const facebook_embed_url = process.env.REACT_APP_FACEBOOK_EMBED_URL;
const facebook_app_id = process.env.REACT_APP_FACEBOOK_APP_ID;
const instagram_embed_url = process.env.REACT_APP_INSTAGRAM_EMBED_URL;

function Home() {
  const [search, setSearch] = useState('');
  const history = useHistory();

  return (
    <section id="main">

      <div className="fl-first-block row mb-md-4 mb-0">
        <div className="fl-first-left col-12 col-sm-12 col-md-8 mb-4">
          <img src={top_image_1} alt="" />
        </div>
        <div className="fl-first-right col-12 col-sm-12 col-md-4 mb-md-4 mb-0 px-md-2">
          <div className='pr-md-2 pr-lg-3 pr-xl-5'>
            <div className="mb-3 home-banner-event">
              <a href='https://queenscup.jp/' target='_blank' rel="noopener noreferrer"><img className="w-100" src={f_banner01_icon} alt="" /></a>
            </div>
            <div className="mb-3 home-banner-event">
              <a href='https://www.flowercard.jp/' target='_blank' rel="noopener noreferrer"><img className="w-100" src={f_banner02_icon} alt="" /></a>
            </div>
            <div className="item">
              <div className="top-form">
                <div style={{ margin: "auto", maxWidth: 350 }}>
                  <div className="form-text">近くのお店を検索</div>

                  <div className="input-group mb-3">
                    <input type="text" className="form-control home-form-search" placeholder="お店の名前を入力"
                      onChange={(e) => {
                        setSearch(e.currentTarget.value)
                      }}
                    />
                    <div className="input-group-append">
                      <button className="btn" type="button" onClick={e => {
                        history.push({
                          pathname: routes.SHOPLIST,
                          state: {
                            search,
                            mode: 'name'
                          }
                        })
                      }}>
                        <img src={icon_search} alt="" />
                      </button>
                    </div>
                  </div>

                  <div>
                    <Link to={{
                      pathname: routes.SHOPS,
                      state: { scrolledId: 'title-search-cities' }
                    }} className="btn-search">
                      <span className='btn-search-text'>市区町名で探す</span>
                      <img src={icon_search_city} alt='search_by_city.png' className='icon-search' />
                    </Link>
                    <Link to={{
                      pathname: routes.SHOPS,
                      state: { scrolledId: 'title-search-map' }
                    }} className="btn-search">
                      <span className='btn-search-text'>マップから探す</span>
                      <img src={icon_search_map} alt='icon_search_map.png' className='icon-search' />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='px-sm-0 px-md-3 px-lg-4 px-xl-5'>
        <div className="row mb-5 fl-facebook-block px-sm-0 px-md-3 px-lg-4 px-xl-5">
          <div className="fl-facebook-left">
            <Link to={routes.RANKING}><img src={ranking01_icon} className="mb-4" alt="" /></Link>
            <div className="mb-4 text-about-home">
              <div className="text-center text-intro-1">花キューピット神奈川について</div>
              <div className="intro-separator" />

              <div className="text-intro-2">
                花キューピット会員は全国4300店の加盟店があり各都道府県別に98の支部に細分化し、神奈川県内にある会員（加盟店）が神奈川支部として営業活動、支部事業を行っています。「花キューピット神奈川」は県内の加盟店165店（10月現在）が所属する神奈川支部の愛称です。
                {/* 花キューピット会員は全国4300店の加盟店があり各都道府県別に98の支部に細分化し<br />
                神奈川県内にある会員（加盟店）が神奈川支部として営業活動、支部事業を行っています。<br />
                <span style={{ marginLeft: -8 }}>「花キューピット神奈川」は県内の加盟店165店（10月現在）が所属する神奈川支部の愛称です。</span> */}
              </div>
            </div>
          </div>
          {/* <div className="fl-facebook-right d-flex flex-column">
            <FacebookWidget
              href={facebook_embed_url}
              appId={facebook_app_id}
              width={350}
              containerClasses="d-none d-sm-none d-md-block d-lg-block d-xl-none text-center"
            />
            <FacebookWidget
              href={facebook_embed_url}
              appId={facebook_app_id}
              containerClasses="d-sm-block d-md-none d-lg-none d-xl-block text-center"
            />
          </div> */}
        </div>
      </div>


      {/* <div className="row mb-5 mb-4">
        <div className="col-12 col-sm-12 col-md-7 text-center mb-4">
          {!!instagram_embed_url && <div className="text-left">
            <div className='col-12 col-sm-12 col-md-12 mt-5 pl-sm-3 pl-md-5 instagram-iframe'>
              <p className="w-100 d-flex align-items-center mb-2">
                <img src={logo_instagram} alt="" style={{ height: '45px', paddingRight: '10px' }} />
              </p>
              <script src="https://snapwidget.com/js/snapwidget.js"></script>
              <iframe
                src={instagram_embed_url}
                className="snapwidget-widget"
                title="Instagram"
                allowTransparency="true"
                frameBorder={0}
                scrolling="no"
                style={{ border: "none", overflow: "hidden", width: "100%" }}
              />
            </div>
          </div>}
        </div>
        <div className="col-12 col-sm-12 col-md-5 pr-xl-5">
          <TwitterWidget
            username={twitter_username}
            containerClasses="twitter-iframe pr-xl-5"
          />
        </div>
      </div> */}

      <div className="row mb-5">
        <div className="col-12 col-sm-12 col-md-7">
          <img src={image02withSquare} alt="" />
        </div>
        <div className="col-12 col-sm-12 col-md-5">
          <div className="image-text-container">
            <div className="text-image-1">
              花とともに心をお届けします
            </div>
            <div className="text-image-2">
              「花キューピット」は、遠く離れた方への贈り物にとても便利なシステム。<br />
              お客様のお近くの花キューピット加盟店でご注文できます。<br />
              <br />
              <br />
              ご注文いただいた情報は、FAXやパソコンを使ってお届け様の最寄の加盟店へ連絡。<br />
              そのお店がお花をお作りしお届けします。<br />
              <br />
              だから新鮮なお花をより早くお届けできるのです。
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12 col-sm-12 col-md-7">
          <div className="image-text-container">
            <div className="text-image-1">
              神奈川のみなさまと創るウェブサイト
            </div>
            <div className="text-image-2">
              花キューピットかながわウェブサイトは<br />
              花キューピット神奈川からお客様へ発進するウェブサイトです。<br />
              <br />
              季節ごとのイベント、キャ  ンペーン、耳より情報、お得な情報を2ウェブサイトでご紹介・発信していきます。<br />
              花とともに、ご注文主様の想いも、しっかりとお届けする。<br />
              私たちがお届けしているのは、ご注文主様のあたたかい「こころ」です。<br />
              花に彩られたあたたかい「こころ」を受け取ったとき、人は大きな感動につつまれます。<br />
              いつまでも忘れられない、わくわくするような、舞い上がるような、あるいはジーンとくるような、静かに慰められるような、そんな美しい感動が生まれます。
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-5">
          <img src={image03withSquare} alt="" />
        </div>
      </div>

      <div style={{ position: "relative" }}>
        <img src={image04} alt="" className='img-4' />
        <div className="row image-text-container-4">
          <div className="col-lg-5">
          </div>
          <div className="col-lg-7 d-flex align-items-center">
            <div className="">
              <div className="text-image-4">
                花とともに、ご注文主様の想いも、しっかりとお届けする。<br />
                私たちがお届けしているのは、ご注文主様のあたたかい「こころ」です。<br />
                花に彩られたあたたかい「こころ」を受け取ったとき、人は大きな感動につつまれます。<br />
                いつまでも忘れられない、わくわくするような、舞い上がるような、あるいはジーンとくるような、静かに慰められるような、そんな美しい感動が生まれます。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
    </section>
  );
}

export default Home;
