import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/common.scss';
import logo_icon from "../../images/common/logo.png";
import routes from 'lib/routes';
import { Link, useHistory } from 'react-router-dom';

function Top() {
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const handleMbNavigate = (e, url) => {
    e.preventDefault();
    toggleMenu();
    history.push(url);
  }

  return (
    <>
      <header id="gHeader">
        <div className="hBox">
          <h1 id="logo-container">
            <Link to="/"><img src={logo_icon} alt="花キューピットかながわHanacupidKanagawa" /></Link>
          </h1>
          <div id="gNavi">
            <ul>
              <li><Link to={routes.SHOPS}>お近くの店舗検索</Link></li>
              <li><Link to={routes.ABOUT}>花キューピット神奈川のご紹介</Link></li>
              <li><Link to={routes.CONTACT}>お問い合わせ</Link></li>
            </ul>
          </div>
        </div>
        <div className={`menu sp ${menuOpen ? 'on' : ''}`} onClick={toggleMenu}>
          <span /><span /><span />
        </div>
      </header>
      <div className={`menuBox ${menuOpen ? 'show' : ''}`}>
        <ul>
          <li><span onClick={e => handleMbNavigate(e, routes.HOME)}>ホーム</span></li>
          <li><span onClick={e => handleMbNavigate(e, routes.SHOPS)}>お近くの店舗検索</span></li>
          <li><span onClick={e => handleMbNavigate(e, routes.ABOUT)}>花キューピット神奈川のご紹介</span></li>
          <li><span onClick={e => handleMbNavigate(e, routes.CONTACT)}>お問い合わせ</span></li>
        </ul>
      </div>
    </>
  );
}

export default Top;
