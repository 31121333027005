const { atom } = require("recoil");

export const centerMapShopsRecoil = atom({
    key: 'centerMapShops',
    default: {
        lat: 35.46738105960409,
        lng: 139.43023681640625
    }
})

export const searchMarkerRecoil = atom({
    key: "searchMarker",
    default: null
})