import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/common.scss';
import logo_icon from "../../images/common/logo.png";
// import sns01_icon from "../../images/common/sns01.png";
import { Link } from 'react-router-dom';
import routes from 'lib/routes';
const facebook_embed_url = process.env.REACT_APP_FACEBOOK_EMBED_URL;
const twitter_embed_url = process.env.REACT_APP_TWITTER_NAVIGATE_URL;
const instagram_embed_url = process.env.REACT_APP_INSTAGRAM_NAVIGATE_URL;

function Footer() {
  return (
    <footer className='mt-0'>
      <div id="fLogoBlock" className="fBox d-flex">
        <div className="flogo">
          <Link to={routes.HOME}><img src={logo_icon} alt="花キューピットかながわHanacupidKanagawa" /></Link>
        </div>
        <div className="fNavi">
          <div className='footer-link'>
            <Link to={routes.SHOPS}>お近くの店舗検索</Link>
            <Link to={routes.ABOUT}>花キューピット神奈川のご紹介</Link>
            <Link to={routes.CONTACT}>お問い合わせ</Link>
          </div>
        </div>
        {/* <div style={{ width: 302 }}></div> */}
        <div className='fSociety'>
          <div className='d-flex'>
            <a href={facebook_embed_url} target='_blank' rel="noopener noreferrer" style={{ fontSize: 19 }}><i className="fa-brands fa-facebook-f"></i></a>
            <a href={twitter_embed_url} target='_blank' rel="noopener noreferrer"><i className="fa-brands fa-x-twitter"></i></a>
            <a href={instagram_embed_url} target='_blank' rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a>
            {/* <a href={facebook_embed_url} target='_blank' rel="noopener noreferrer"><img className='sns-img' src={sns01_icon} alt='フェイスブックに行く' /></a> */}
          </div>
        </div>
      </div>
      <p className="copyright">Copyright HANACUPID-KANAGAWA All rights reserved.</p>
    </footer>
  );
}

export default Footer;
