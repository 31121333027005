import React, { memo } from 'react';
import CONTACT_WALL from "../../images/contact/contactwall.jpg"

const Contact = memo(() => {
  return (
    <>
      <div className='information-banner'>
        <img src={CONTACT_WALL} alt='Contact.png' />
        <h1 className='information-header'>CONTACT</h1>
      </div>
      <div className='information-container' style={{maxWidth: 735}}>
        <h2 className='information-title'>お問い合わせ</h2>
        <p className='information-content'>加盟店へのお問い合わせは加盟店に直接お問い合わせください。</p>
        <p className='information-content'>花キューピットかながわウェブサイトへのお問い合わせ、 一般社団法人JFTD 神奈川支部 (花 キューピットかながわ) へのお問い合わせは下記またはお問い合わせフォームよりお問い合 わせください。</p>
        <p className='information-content'>一般社団法人JFTD 神奈川支部事務局(花キューピットかながわ)</p>
      </div>
    </>
  )
})

export default Contact