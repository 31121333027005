import axios from "axios";
const API_HOST = process.env.REACT_APP_API_HOST || " https://kanagawa879.com/api/";
const API_VERSION = process.env.REACT_APP_API_VERSION || "v1";

export const getData = async (url) => {
  try {
    let {data} = await axios.get(API_HOST + API_VERSION + url);
    return data;
  } catch (e) {
    console.error('getData error', e);
    throw new Error(e);
  }
}

export const postData = async (url, body = {}) => {
  try {
    let {data} = await axios.post(API_HOST + API_VERSION + url, body);
    return data;
  } catch (e) {
    console.error('postData error', e);
    throw new Error(e);
  }
}