import React, { memo } from 'react';
import mark_time_image from "../../images/common/mark_time.png";
import mark_rest_image from "../../images/common/mark_rest.png";
import mark_ad_image from "../../images/common/mark_ad.png";
import mark_tel_image from "../../images/common/mark_tel.png";
import routes from 'lib/routes';
import { Link } from 'react-router-dom';

const ShopItem = memo(({shop}) => {
  return (
    <div className="container-fluid shop-item-container">
        <div className='px-4'>
            <div className='row'>
                <div className="col-md-4">
                    <img src={`https://www.kanagawa879.com/uploads/blogs/${shop.blog_id}/topimage.jpg`} alt="" className='w-100' />
                </div>
                <div className="col-md-8">
                    <div className='shop-item-name'>{shop.name}</div>
                    <div className='my-3'>
                        <div className='row'>
                            <div className="col-sm-6">
                                <div className="d-flex align-items-start shop-item-info">
                                    <img className="contact-image" src={mark_tel_image} alt="" />
                                    <span>{shop.tel}</span>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="d-flex align-items-start shop-item-info">
                                    <img className="contact-image" src={mark_ad_image} alt="" />
                                    <span>{ shop.address }</span>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-6">
                                <div className="d-flex align-items-start shop-item-info">
                                    <img className="contact-image" src={mark_time_image} alt="" />
                                    <span>{ shop.eigyo }</span>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="d-flex align-items-start shop-item-info">
                                    <img className="contact-image" src={mark_rest_image} alt="" />
                                    <span>{ shop.teikyu }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Link to={`${routes.SHOPDETAIL}/${shop.code}`} className='shop-item-link'>店舗詳細ページ</Link>
                    </div>
                </div>
            </div>
            <p className='mt-4 mb-5'>
                {shop.description}
            </p>
        </div>
        <hr />
    </div>
  )
})

export default ShopItem