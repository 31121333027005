import React, { memo } from 'react'
import ABOUT_WALL from "../../images/about/aboutwall.jpg"

const About = memo(() => {
  return (
    <>
      <div className='information-banner'>
        <img src={ABOUT_WALL} alt='about.png' />
        <h1 className='information-header'>ABOUT US</h1>
      </div>
      <div className='information-container' style={{ maxWidth: 880 }}>
        <h2 className='information-title'>花キューピット神奈川のご紹介</h2>
        <p className='information-content'>
          花キューピット会員は全国4300店の加盟店があり各都道府県別に98の支部に細分化し、 神奈川県内にある会員<br />
          (加盟店) が神奈川支部として営業活動、 支部事業を行っています。<br />
          <span className='information-content-special'>「花キューピット神奈川」 は県内の加盟店165店 (10月現在) が所属する神奈川支部の愛称です。</span>
        </p>
        <h2 className='information-title'>花キューピットかながわウェブサイトについて</h2>
        <p className='information-content'>花キューピットかながわウェブサイトは花キューピット神奈川からお客様へ発進するウェブサイトです。</p>
        <p className='information-content'>季節ごとのイベント、キャンペーン、 耳より情報、お得な情報を2ウェブサイトでご紹介します。</p>
        <h2 className='information-title'>花キューピットとは</h2>
        <p className='information-content'>
          花キューピットは全国の花店 4,300店によるお花の配達ネットワークです。<br />
          花キューピットは全国の花店 4,スピーディーに新鮮なお花を大切な方のもとへお届けいたします。<br />
          「花キューピット」 は、 遠く離れた方への贈り物にとても便利なシステム。<br />
          お客様のお近くの花キューピット加盟店でご注文できます。<br />
          ご注文いただいた情報は、 FAXやパソコンを使ってお届け様の最寄の加盟店へ連絡。<br />
          そのお店がお花をお作りしお届けします。 だから新鮮なお花をより早くお届けできるのです。<br />
        </p>
        <h2 className='information-title'>花キューピット神奈川事務局</h2>
        <p className='information-content' style={{ marginBottom: "180px" }}>〒231-0012 横浜市中区相生町1-7-2 フローリスト デュアイ3F</p>
      </div>
    </>
  )
})

export default About