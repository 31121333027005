export default {
    HOME: "",
    SHOPS: "/search",
    ABOUT: "/about",
    CONTACT: "/contact",
    SHOPLIST: "/shoplist",
    SHOPDETAIL: "/shops",
    RANKING: "/flowers",
    VOTE: "/r/vote",
    RECOMMEND: "/r/recommend",
    SHOPINFO: "/shops/:shop_code"
}

// export default {
//     HOME: "/n",
//     SHOPS: "/n/search",
//     ABOUT: "/n/about",
//     CONTACT: "/n/contact",
//     SHOPLIST: "/n/shoplist",
//     SHOPDETAIL: "/n/shops",
//     RANKING: "/n/flowers",
//     VOTE: "/n/r/vote",
//     RECOMMEND: "/n/r/recommend",
//     SHOPINFO: "/n/shops/:shop_code"
// }