import React from 'react';
import { FacebookProvider, Page } from 'react-facebook';

function FacebookWidget(props) {
  if (!props.href) return null;
  if (!props.appId) return null;

  return (
    <div className={props.containerClasses || ''}>
      <FacebookProvider appId={props.appId}>
        <Page href={props.href} tabs="timeline" height={500} width={props.width || 500} />
      </FacebookProvider>
    </div>
  );
}

export default FacebookWidget;
