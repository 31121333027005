import React from 'react';
import styled from 'styled-components';

const Frame = styled.div`
    width: 260px;
    background-color: white;
    border: ${props => props.selected ? "6px solid #8ec456" : "6px solid #ffffff"};
    cursor: pointer;
    margin-bottom: 30px;
`;
const FlowerImage = styled.img`
    width: 260px;
    height: 170px;
    background-color: gray;
    object-fit: cover;
    box-shadow: 3px 3px 5px #909090;
`;
const SelectBtn = styled.button`
    text-align: center;
    width: 100%;
    height: 40px;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    border-radius: 0 0 3px 3px;
    box-shadow: 3px 3px 5px #909090;
`;
const EntryNo = styled.div`
    padding: 5px 0;
    color: ${props => props.selected ? "#ffffff" : "#8ec456"} ;
    background-color: ${props => props.selected ? "#8ec456" : "#ffffff"};
    font-weight: bold;
    box-shadow: 3px 3px 5px #909090;
    border: solid 1px #8ec456;
    font-size: 1.2em;
`;
const FlowerSelect = ({
    src,
    id,
    entryno,
    selected = false,
    selectFlower = null,
    selectShop = null
}) => {
    return (
        <Frame selected={selected}>
            <EntryNo selected={selected}>エントリーNo. {entryno}</EntryNo>
            <FlowerImage src={src} data-id={id} onClick={selectShop} />
            <SelectBtn onClick={selectFlower}>{selected ? '選択を解除' : 'このお花を選択'}</SelectBtn>
        </Frame>
    )
};

export default FlowerSelect;